import { ethers } from 'ethers';
import { useState } from 'react';
import axios from 'axios';
import { getContract } from '../../utils/getContract';
import Loader from '../loader/Loader';
import tokenAbi from '../../ABI/emivnAbi.json';
import stakingAbi from '../../ABI/stakingAbi.json';
import './SendTokens.css';
import { stakingAddress, tokenAddress } from '../../config/config';

const TERMS = [90, 180, 360, 720];

const SendTokens = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [tokensCount, setTokensCount] = useState('');
  const [term, setTerm] = useState(TERMS[0]);
  const [loading, setLoading] = useState(false);

  const handleAddressChange = (event) => {
    setWalletAddress(event.target.value);
  };

  const handleTokensCountChange = (value) => {
    const parsedValue = parseFloat(value);
    setTokensCount(isNaN(parsedValue) || parsedValue < 0 ? '' : value);
  };

  const handleTermChange = (event) => {
    setTerm(parseInt(event.target.value, 10));
  };

  const approveTokens = async () => {
    try {
      setLoading(true);
      const tokenContract = await getContract(tokenAddress, tokenAbi);
      const tx = await tokenContract.approve(
        stakingAddress,
        ethers.parseUnits('100000000', 18),
        {
          gasLimit: 250_000,
        },
      );
      await tx.wait();
      alert('Апрув успешно произведен');
    } catch (error) {
      console.error('Approval error:', error.message);
      alert('Ошибка при апруве');
    } finally {
      setLoading(false);
    }
  };

  const sendTokens = async () => {
    if (!walletAddress || !ethers.isAddress(walletAddress)) {
      alert('Неправильный адрес кошелька');
      return;
    }

    try {
      setLoading(true);
      const stakingContract = await getContract(stakingAddress, stakingAbi);
      const amount = ethers.parseUnits(tokensCount, 18);

      const tx = await stakingContract.stake(walletAddress, amount, term, {
        gasLimit: 250_000,
      });
      const receipt = await tx.wait();

      if (receipt.status === 1) {
        const stakeId = await stakingContract.getMyLastStakedId(walletAddress);

        const transactionData = {
          stakeId: `${walletAddress}${stakeId.toString() - 1}`,
          receiver: walletAddress,
          amount: tokensCount,
          days: term,
          hash: tx.hash,
        };

        await axios.post(
          `${process.env.REACT_APP_API_URL}/wallet/stake`,
          transactionData,
        );
        window.location.reload();
      }
    } catch (error) {
      console.error('Transaction error:', error.message);
      alert('Ошибка в транзакции');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="send_tokens_container">
      <h3>ОТПРАВИТЬ ТОКЕНЫ</h3>
      <div className="inputs_container">
        <input
          type="text"
          placeholder="Адрес кошелька"
          value={walletAddress}
          onChange={handleAddressChange}
        />
        <input
          type="text"
          placeholder="Количество токенов"
          value={tokensCount}
          onChange={(e) => handleTokensCountChange(e.target.value)}
        />
        <select onChange={handleTermChange} value={term}>
          {TERMS.map((term) => (
            <option key={term} value={term}>
              {term} дней
            </option>
          ))}
        </select>
      </div>
      <div className="buttons_container">
        <button onClick={approveTokens}>
          Апрув для смарт контракта стейкинга
        </button>
        <button onClick={sendTokens}>Отправить в стейкинг</button>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default SendTokens;
