import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import tokenAbi from '../../ABI/emivnAbi.json';
import { connectMetamask } from './walletUtils';
import {
  stakingAddress,
  tokenAddress,
  admins,
  mainAdmin,
  claimAdmin,
} from '../../config/config';
import './Header.css';

const {
  REACT_APP_PROVIDER: providerUrl,
  REACT_APP_BALANCE_API: balanceApiUrl,
} = process.env;

const provider = new ethers.JsonRpcProvider(providerUrl);
const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, provider);

const Header = () => {
  const [emivnBalance, setEmivnBalance] = useState('Загрузка...');
  const [mainAdminUsdBalance, setMainAdminUsdBalance] = useState('Загрузка...');
  const [claimAdminUsdBalance, setClaimAdminUsdBalance] =
    useState('Загрузка...');
  const [metaMaskConnected, setMetaMaskConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');

  const shortAddress = (address) => {
    return address ? address.substr(0, 6) + '...' + address.substr(-5) : '';
  };

  const disconnectWalletHandler = () => {
    if (window.ethereum) {
      try {
        if (typeof window.ethereum.disconnect === 'function') {
          window.ethereum.disconnect();
        }

        setMetaMaskConnected(false);
        setWalletAddress('');
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchEmivnBalance = async () => {
    try {
      const balance = await tokenContract.balanceOf(stakingAddress);
      const formattedBalance = ethers.formatUnits(balance, 18);
      setEmivnBalance(formattedBalance);
    } catch (error) {
      console.error('Error fetching token balance:', error);
      setEmivnBalance('Ошибка');
    }
  };

  const fetchUsdBalance = async (address, setBalanceCallback) => {
    try {
      const balance = await provider.getBalance(address);
      const bnbBalance = parseFloat(ethers.formatEther(balance));

      try {
        const response = await axios.get(
          `${balanceApiUrl}/simple/price?ids=binancecoin&vs_currencies=usd`,
        );
        const bnbUsdPrice = response.data?.binancecoin?.usd;

        if (bnbUsdPrice) {
          const usdBalance = (bnbBalance * bnbUsdPrice).toFixed(2);
          setBalanceCallback(usdBalance);
        } else {
          throw new Error('Invalid response for BNB price');
        }
      } catch (error) {
        console.error('Error fetching BNB price:', error);
        setBalanceCallback('Ошибка');
      }
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
      setBalanceCallback('Ошибка');
    }
  };

  useEffect(() => {
    const fetchBalances = async () => {
      await Promise.all([
        fetchEmivnBalance(),
        fetchUsdBalance(mainAdmin, setMainAdminUsdBalance),
        fetchUsdBalance(claimAdmin, setClaimAdminUsdBalance),
      ]);
    };

    fetchBalances();
  }, []);

  return (
    <>
      <h1>Стейкинг проект EMIVN</h1>
      <header>
        <div className="header__left">
          <div className="header__admin-section">
            <div className="header__admin-title">Главный админ:</div>
            <div className="header__admin">{mainAdmin}</div>
          </div>
          <div className="header__admins">
            <div className="header__admin-title">Админы:</div>
            {admins.map((admin, index) => (
              <span key={index}>{admin}</span>
            ))}
          </div>
          <div className="header__admin-section">
            <div className="header__admin-title">
              <span style={{ color: 'red' }}>Claim</span> кошелек:
            </div>
            <div className="header__admin">{claimAdmin}</div>
          </div>
        </div>

        <div className="header__right">
          <div className="header__info">
            Баланс токенов на{' '}
            <a
              href={`https://bscscan.com/address/${stakingAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              контракте
            </a>
            : <strong>{emivnBalance}</strong> EMIVN
          </div>
          <div className="header__info">
            Баланс кошелька{' '}
            <a
              href={`https://bscscan.com/address/${claimAdmin}`}
              target="_blank"
              rel="noreferrer"
            >
              {shortAddress(mainAdmin)}
            </a>
            : <strong>{mainAdminUsdBalance}</strong> USD
          </div>
          <div className="header__info">
            Баланс <span style={{ color: 'red' }}>claim</span> кошелька{' '}
            <a
              href={`https://bscscan.com/address/${claimAdmin}`}
              target="_blank"
              rel="noreferrer"
            >
              {shortAddress(claimAdmin)}{' '}
            </a>
            : <strong>{claimAdminUsdBalance}</strong> USD
          </div>
          <div className="header__buttons">
            {metaMaskConnected ? (
              <button
                className="header__button"
                onClick={disconnectWalletHandler}
              >
                {shortAddress(walletAddress)}
              </button>
            ) : (
              <button
                className="header__button"
                onClick={() =>
                  connectMetamask(setWalletAddress, setMetaMaskConnected)
                }
              >
                Подключить MetaMask
              </button>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
